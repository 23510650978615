import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _44b764da = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _9882dd00 = () => interopDefault(import('../pages/architecture/index.vue' /* webpackChunkName: "pages/architecture/index" */))
const _08440926 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7d57180a = () => interopDefault(import('../pages/team/index.vue' /* webpackChunkName: "pages/team/index" */))
const _7b255d7e = () => interopDefault(import('../pages/architecture/industrial/index.vue' /* webpackChunkName: "pages/architecture/industrial/index" */))
const _fd481196 = () => interopDefault(import('../pages/architecture/residential/index.vue' /* webpackChunkName: "pages/architecture/residential/index" */))
const _0e186bc8 = () => interopDefault(import('../pages/architecture/solutions.vue' /* webpackChunkName: "pages/architecture/solutions" */))
const _2f94a97e = () => interopDefault(import('../pages/team/experts.vue' /* webpackChunkName: "pages/team/experts" */))
const _796ebc36 = () => interopDefault(import('../pages/architecture/industrial/_slug.vue' /* webpackChunkName: "pages/architecture/industrial/_slug" */))
const _7fa555ed = () => interopDefault(import('../pages/architecture/residential/_slug.vue' /* webpackChunkName: "pages/architecture/residential/_slug" */))
const _9bf01f90 = () => interopDefault(import('../pages/architecture/_slug.vue' /* webpackChunkName: "pages/architecture/_slug" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _44b764da,
    name: "about___sr"
  }, {
    path: "/architecture",
    component: _9882dd00,
    name: "architecture___sr"
  }, {
    path: "/contact",
    component: _08440926,
    name: "contact___sr"
  }, {
    path: "/en",
    component: _0547af50,
    name: "index___en"
  }, {
    path: "/team",
    component: _7d57180a,
    name: "team___sr"
  }, {
    path: "/architecture/industrial",
    component: _7b255d7e,
    name: "architecture-industrial___sr"
  }, {
    path: "/architecture/residential",
    component: _fd481196,
    name: "architecture-residential___sr"
  }, {
    path: "/architecture/solutions",
    component: _0e186bc8,
    name: "architecture-solutions___sr"
  }, {
    path: "/en/about",
    component: _44b764da,
    name: "about___en"
  }, {
    path: "/en/architecture",
    component: _9882dd00,
    name: "architecture___en"
  }, {
    path: "/en/contact",
    component: _08440926,
    name: "contact___en"
  }, {
    path: "/en/team",
    component: _7d57180a,
    name: "team___en"
  }, {
    path: "/team/experts",
    component: _2f94a97e,
    name: "team-experts___sr"
  }, {
    path: "/en/architecture/industrial",
    component: _7b255d7e,
    name: "architecture-industrial___en"
  }, {
    path: "/en/architecture/residential",
    component: _fd481196,
    name: "architecture-residential___en"
  }, {
    path: "/en/architecture/solutions",
    component: _0e186bc8,
    name: "architecture-solutions___en"
  }, {
    path: "/en/team/experts",
    component: _2f94a97e,
    name: "team-experts___en"
  }, {
    path: "/en/architecture/industrial/:slug",
    component: _796ebc36,
    name: "architecture-industrial-slug___en"
  }, {
    path: "/en/architecture/residential/:slug",
    component: _7fa555ed,
    name: "architecture-residential-slug___en"
  }, {
    path: "/architecture/industrial/:slug",
    component: _796ebc36,
    name: "architecture-industrial-slug___sr"
  }, {
    path: "/architecture/residential/:slug",
    component: _7fa555ed,
    name: "architecture-residential-slug___sr"
  }, {
    path: "/en/architecture/:slug",
    component: _9bf01f90,
    name: "architecture-slug___en"
  }, {
    path: "/architecture/:slug",
    component: _9bf01f90,
    name: "architecture-slug___sr"
  }, {
    path: "/",
    component: _0547af50,
    name: "index___sr"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
