<template>
  <div class="bg-white">
    <div class="container">
      <nav class="flex align-items-center justify-between">
        <nuxt-link :to="localePath('index')" no-prefetch>
          <div></div>
          <img
            :class="[{ bigger: home }, 'logo']"
            src="/img/logo.png"
            alt="folic logo"
          />
        </nuxt-link>
        <div class="desktop-menu hidden md:flex items-center">
          <nuxt-link :to="localePath('architecture')" exact>{{
            $t('architecture')
          }}</nuxt-link>
          <nuxt-link :to="localePath('about')" exact>{{
            $t('about')
          }}</nuxt-link>
          <nuxt-link :to="localePath('team')" exact>
            {{ $t('team') }}
          </nuxt-link>

          <!-- <a
            href="javascript:void(0)"
            :class="[{ open: showArchitecture }, 'has-dropdown']"
            @click.prevent="showArchitecture = !showArchitecture"
          >
            <span>{{ $t('architecture') }}</span>
            <v-icon class="dropdown-icon" name="chevron-down"></v-icon>
            <div :class="[{ open: showArchitecture }, 'dropdown-content']">
              <nuxt-link
                :to="localePath('architecture-residential')"
                @click.passive="showArchitecture = false"
              >
                {{ $t('residential') }}
              </nuxt-link>
              <nuxt-link
                :to="localePath('architecture-industrial')"
                @click.capture="showArchitecture = false"
              >
                {{ $t('industrial') }}
              </nuxt-link>
              <nuxt-link
                :to="localePath('architecture-commercial')"
                @click.capture="showArchitecture = false"
              >
                {{ $t('commercial') }}
              </nuxt-link>
            </div>
          </a>-->
          <!-- 
          <a
            
            href="javascript:void(0)"
            :class="[{ open: showUrbanism }, 'has-dropdown']"
            @click.prevent="showUrbanism = !showUrbanism"
          >
            <span>{{ $t('urbanism') }}</span>
            <v-icon class="dropdown-icon" name="chevron-down"></v-icon>
            <div :class="[{ open: showUrbanism }, 'dropdown-content']">
              <nuxt-link :to="localePath('urbanism.plan')">
                {{ $t('plans') }}
              </nuxt-link>
              <nuxt-link :to="localePath('urbanism.projects')">
                {{ $t('urbanistic_projects') }}
              </nuxt-link>
            </div>
          </a>-->

          <!-- <a href="#">{{ $t('publications') }}</a> -->
          <!-- <a
            href="javascript:void(0)"
            :class="[{ open: showTeam }, 'has-dropdown']"
            @click.prevent="showTeam = !showTeam"
          >
            <span>{{ $t('team') }}</span>
            <v-icon class="dropdown-icon" name="chevron-down"></v-icon>
            <div :class="[{ open: showTeam }, 'dropdown-content']">
              <nuxt-link :to="localePath('team-experts')">
                {{ $t('expert_team') }}
              </nuxt-link>
              <nuxt-link :to="localePath('team.managment')">
                {{ $t('management') }}
              </nuxt-link>
              <nuxt-link :to="localePath('team.associates')">
                {{ $t('external_associates') }}
              </nuxt-link>
            </div>
          </a>-->

          <nuxt-link :to="localePath('contact')">{{ $t('contact') }}</nuxt-link>

          <nuxt-link
            v-for="locale in availableLocales"
            :key="locale.code"
            :to="switchLocalePath(locale.code)"
            exact
            class="uppercase"
            >{{ locale.code }}</nuxt-link
          >
        </div>
        <div
          :active="menuOpen"
          @click.prevent="menuOpen = !menuOpen"
          class="md:hidden menu-icon flex"
        >
          <menu-icon :active="menuOpen" class="self-center"></menu-icon>
        </div>

        <div :class="[{ visible: menuOpen }, 'sidenav']">
          <nuxt-link :to="localePath('architecture')" exact>{{
            $t('architecture')
          }}</nuxt-link>
          <nuxt-link :to="localePath('about')">{{ $t('about') }}</nuxt-link>

          <!-- <nuxt-link :to="localePath('services')">
            {{ $t('services') }}
          </nuxt-link>-->
          <!-- <a
            href="javascript:void(0)"
            :class="[{ open: showArchitecture }, 'has-dropdown']"
            @click.prevent="showArchitecture = !showArchitecture"
          >
            <span>{{ $t('architecture') }}</span>
            <v-icon class="dropdown-icon" name="chevron-down"></v-icon>
          </a>
          <div :class="[{ open: showArchitecture }, 'dropdown-content']">
            <nuxt-link :to="localePath('architecture-constructions')">
              {{ $t('constructions') }}
            </nuxt-link>
            <nuxt-link :to="localePath('architecture-solutions')">
              {{ $t('conceptual_solutions') }}
            </nuxt-link>
          </div>
          <a
            href="javascript:void(0)"
            :class="[{ open: showUrbanism }, 'has-dropdown']"
            @click.prevent="showUrbanism = !showUrbanism"
          >
            <span>{{ $t('urbanism') }}</span>
            <v-icon class="dropdown-icon" name="chevron-down"></v-icon>
          </a>
          <div :class="[{ open: showUrbanism }, 'dropdown-content']">
            <nuxt-link :to="localePath('urbanism.plan')">
              {{ $t('plans') }}
            </nuxt-link>
            <nuxt-link :to="localePath('urbanism.projects')">
              {{ $t('urbanistic_projects') }}
            </nuxt-link>
          </div>
          <a href="#">{{ $t('publications') }}</a>
          <a
            href="javascript:void(0)"
            :class="[{ open: showTeam }, 'has-dropdown']"
            @click.prevent="showTeam = !showTeam"
          >
            <span>{{ $t('team') }}</span>
            <v-icon class="dropdown-icon" name="chevron-down"></v-icon>
          </a>
          <div :class="[{ open: showTeam }, 'dropdown-content']">
            <nuxt-link :to="localePath('team-experts')">
              {{ $t('expert_team') }}
            </nuxt-link>
            <nuxt-link :to="localePath('team.managment')">
              {{ $t('management') }}
            </nuxt-link>
            <nuxt-link :to="localePath('team.associates')">
              {{ $t('external_associates') }}
            </nuxt-link>
          </div>-->
          <nuxt-link :to="localePath('contact')">{{ $t('contact') }}</nuxt-link>
          <!-- <a href="#">{{ $t('contact') }}</a> -->

          <div class="contact-menu px-8 pt-16">
            <p>Folic Architects doo</p>
            <p>Beogradska 52</p>
            <a class="inline" href="tel:+381642420733">+381 64 2420733</a>
            <a class="text-sm" href="mailto:office@folicarchitects.rs"
              >office@folicarchitects.rs</a
            >
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { MenuIcon } from 'vue-transformicons/dist/vue-transformicons.ssr'

export default {
  components: {
    MenuIcon
  },
  data() {
    return {
      home: false,
      menuOpen: false,
      showArchitecture: false,
      showUrbanism: false,
      showTeam: false
    }
  },
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)
    }
  },
  watch: {
    '$route.path'() {
      console.log('route change')
      this.menuOpen = false
      this.showArchitecture = false
      this.showUrbanism = false
      this.showTeam = false
      this.home = this.$route.path === '/'
    }
  },
  mounted() {
    console.log('navbar mount')
    this.home = this.$route.path === '/'
  },
  methods: {
    toggleMenu() {
      console.log('menu toggle')
      this.menuOpen = !this.menuOpen
    }
  }
}
</script>

<style lang="postcss" scoped>
.desktop-menu a {
  margin-left: 15px;
}
nav {
  height: 75px;
  background: white;
  width: 100%;
  z-index: 10;
  padding: 5px 0;
}
.logo {
  height: 100%;
  width: auto;
  max-width: 100px;
  margin: 0;
  transition: 0.5s;
  transform: none;
}
.logo.bigger {
  /* outline: 3px solid red; */
  background: white;
  height: 200%;
  max-width: 150px;
  z-index: 1555;
}

.menu-icon {
  width: 50px;
}

.menu {
  position: absolute;
}

.sidenav {
  height: calc(100% - 75px); /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: absolute; /* Stay in place */
  z-index: 500; /* Stay on top */
  top: 75px; /* Stay at the top */
  right: 0;
  background-color: white;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 20px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

.sidenav.visible {
  width: 100vw;
}

@screen sm {
  .sidenav.visible {
    width: 350px;
  }
}
/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: black;
  font-weight: 600;
  display: block;
  transition: 0.3s;
  text-transform: uppercase;
  z-index: 12;
}

.dropdown-content {
  padding-left: 15px;
  display: block;
  transition: 0.5s;
  max-height: 0;
  height: 0;
  opacity: 0;
  display: none;
}

.dropdown-content.open {
  max-height: 300px;
  height: auto;
  opacity: 1;
  display: block;
  z-index: 50;
  text-transform: uppercase;
}

.desktop-menu .dropdown-content.open {
  position: absolute;
  /* top: 65px; */
  top: 50px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: white;
  a {
    font-size: 14px;
  }
}
.dropdown-icon {
  margin-left: 10px;
  height: 25px;
  width: 25px;
  transition: 0.3s ease-in;
}
.desktop-menu .dropdown-icon {
  margin-left: 5px;
  height: 20px;
  width: 20px;
}
.has-dropdown {
  display: flex !important;
  align-items: center;
  position: relative;
}

.dropdown-content a {
  font-weight: 400;
  font-size: 18px;
  color: black !important;

  &:hover {
  }
}
.has-dropdown.open .dropdown-icon {
  transform: rotate(180deg);
}
.contact-menu a {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-transform: none;
}

a:hover {
  color: #11b3f0;
}

.bg-white {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.nuxt-link-active {
  /* text-decoration: none !important; */
  color: #11b3f0;
}
</style>
